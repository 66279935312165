import cn from 'classnames';

import { Icon, Upload, UploadProps } from 'gazprom-ui-lib';

import ImageModalUploadLoader from 'containers/image-modal/image-modal-upload/editor-image-modal-upload-loader';
import WithLoader from 'containers/wrappers/with-loader';

import FileContent, { FileContentProps } from '../file-content';
import s from './FileUpload.module.scss';

export interface FileUploadProps extends FileContentProps {
  isLoading: boolean;
  className?: string;
  isError?: boolean;
  availableFileTypes?: string[];
  resetHandler: () => void;
  beforeUpload?: UploadProps['beforeUpload'];
  customRequest?: UploadProps['customRequest'];
}

const FileUpload = (props: FileUploadProps) => {
  const {
    icon,
    title,
    description,
    isLoading,
    availableFileTypes,
    isError = false,
    className,
    beforeUpload,
    customRequest,
    resetHandler,
  } = props;

  return (
    <Upload.Dragger
      disabled={isLoading}
      beforeUpload={beforeUpload}
      accept={availableFileTypes?.join(', ')}
      showUploadList={false}
      multiple={false}
      customRequest={customRequest}
      className={cn(className, s.wrapper, isError ? s.errorStatus : '')}>
      <WithLoader isLoading={isLoading} loader={<ImageModalUploadLoader />}>
        <div className={s.container}>
          <FileContent icon={icon} title={title} description={description} isError={isError} />
          {isError && (
            <button className={s.resetButton} onClick={resetHandler} type="button">
              <Icon name="refresh" />
            </button>
          )}
        </div>
      </WithLoader>
    </Upload.Dragger>
  );
};

export default FileUpload;
