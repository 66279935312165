import { MouseEvent } from 'react';

import { t } from 'tools/i18n';

import { Button, ColumnsType, Flex, Icon } from 'gazprom-ui-lib';

import TableText from 'components/table/table-text';

import { RolesPersonType } from 'types/person.types';

interface Props {
  handleOpenAccessCodeModal: ({ personId }: { personId: string }) => void;
}

interface OnAccessCodeBtnClickProps {
  e: MouseEvent<HTMLElement, globalThis.MouseEvent>;
  personId: string;
}

const useColumns = (props: Props): ColumnsType<RolesPersonType> => {
  const { handleOpenAccessCodeModal } = props;

  const userRenderer = (_: unknown, row: RolesPersonType) => {
    return (
      <TableText size="14" weight="500">
        {row.lastName} {row.firstName} {row.patronymic}
      </TableText>
    );
  };

  const employersRenderer = (employers: RolesPersonType['employers']) => {
    return (
      <TableText size="14" weight="500" type="secondary">
        {employers.map((employer) => employer.name).join(', ')}
      </TableText>
    );
  };

  const phoneEmailRenderer = (text: string) => {
    return (
      <TableText size="14" weight="500" type="secondary">
        {text}
      </TableText>
    );
  };

  const adminRenderer = (isAdmin: boolean) => {
    return (
      <Icon
        name="okFilled"
        color={`var(${isAdmin ? '--color-success' : '--color-placeholder-25'})`}
      />
    );
  };

  const actionsRenderer = () => {
    return (
      <Flex justify="flex-end">
        <Button leftIcon="edit" size="small" type="link">
          {t('common_edit_short')}
        </Button>
      </Flex>
    );
  };

  const onAccessCodeBtnClick = ({ e, personId }: OnAccessCodeBtnClickProps) => {
    e.stopPropagation();
    handleOpenAccessCodeModal({ personId });
  };

  return [
    {
      title: t('common_user'),
      dataIndex: 'user',
      key: 'user',
      render: userRenderer,
    },
    {
      title: t('common_organization'),
      dataIndex: 'employers',
      key: 'employers',
      render: employersRenderer,
    },
    {
      title: t('common_phone'),
      dataIndex: 'phone',
      key: 'phone',
      render: phoneEmailRenderer,
    },
    {
      title: t('common_email'),
      dataIndex: 'email',
      key: 'email',
      render: phoneEmailRenderer,
    },
    {
      title: t('common_admin_access'),
      dataIndex: 'isAdmin',
      align: 'center',
      key: 'isAdmin',
      render: adminRenderer,
    },
    {
      title: t('common_access_code'),
      dataIndex: 'refreshCode',
      key: 'refreshCode',
      align: 'center',
      render: (refreshCode: boolean, { id: personId }) => {
        return (
          <Button
            disabled={!refreshCode}
            onClick={(e) => onAccessCodeBtnClick({ e, personId })}
            type="link"
            leftIcon="sendFilled">
            {t('common_send')}
          </Button>
        );
      },
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      render: actionsRenderer,
    },
  ];
};

export default useColumns;
