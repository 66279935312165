import { ChangeEvent, FC, useEffect, useState } from 'react';

import { t } from 'tools/i18n';

import { useLazyGetEmployersListQuery } from 'services/employer/employerApiService';
import {
  GetEmployersPropsType,
  GetEmployersResponse,
} from 'services/employer/employerApiService.types';

import FiltersContainer from 'containers/filters';
import FiltersV2 from 'containers/filters-v2';
import FilterCheckboxGroup from 'containers/filters/filter-checkbox-group';
import FilterCollapse from 'containers/filters/filter-collapse';
import FilterCustomWrapper from 'containers/filters/filter-custom-wrapper';
import FilterHeader from 'containers/filters/filter-header';

const Filters: FC = () => {
  const [getEmployers, employersResult] = useLazyGetEmployersListQuery({ selectFromResult });
  const [requestBody, setRequestBody] = useState({ page: 1, employerName: '' });

  const employersSearchHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setRequestBody((prev) => ({ ...prev, employerName: e.target.value }));
  };

  useEffect(() => {
    const defaultBody: GetEmployersPropsType = {
      page: 1,
      size: 500,
    };

    if (requestBody.employerName) {
      defaultBody.name = requestBody.employerName;
    }

    getEmployers(defaultBody);
  }, [getEmployers, requestBody.employerName]);

  const filterItems = [
    {
      key: 0,
      label: <FilterHeader valueKey="employerId">{t('common_organization')}</FilterHeader>,
      children: (
        <FilterCheckboxGroup
          valueKey="employerId"
          optionFilterProp="value"
          options={employersResult?.employersOptions ?? []}
          showSearch
          searchProps={{
            placeholder: t('common_organization_search'),
            onChange: employersSearchHandler,
          }}
        />
      ),
    },
  ];

  const preparedFilters = [
    { label: t('common_document_number'), value: 'noNumber' },
    { label: t('common_document_date'), value: 'noDate' },
    { label: t('common_document_type'), value: 'noType' },
    { label: t('common_xml_file'), value: 'noXmlFile' },
  ];

  return (
    <FiltersV2.TableFilters>
      <FiltersV2.Search />
      <FiltersContainer
        showClearAll
        overlay={
          <>
            <FilterCollapse items={filterItems} />
            <FilterCustomWrapper>
              <FilterHeader valueKey="filledValues">{t('common_unfilled_values')}</FilterHeader>
              <FilterCheckboxGroup
                usePadding={false}
                valueKey="filledValues"
                options={preparedFilters}
              />
            </FilterCustomWrapper>
          </>
        }
      />
    </FiltersV2.TableFilters>
  );
};
export default Filters;

export const selectFromResult = ({ data }: { data?: GetEmployersResponse }) => ({
  employersOptions: data?.content.map(({ id, name }) => ({ value: id, label: name })) ?? [],
});
