import { useEffect } from 'react';

import dayjs from 'dayjs';

import { FormInstance } from 'gazprom-ui-lib';

import { RecruitDocumentType } from 'types/recruit.types';

import { DOCUMENT_TYPES } from '../../utils';

interface Props {
  form: FormInstance;
  docInfo?: RecruitDocumentType | null;
}

const useDefaultValues = (props: Props) => {
  const { form, docInfo } = props;

  useEffect(() => {
    if (docInfo) {
      const file = docInfo?.document?.files?.[0];
      const name = file?.description ?? '';
      const docNumber = file?.numberDoc ?? '';
      const docDate = file?.dateDoc ? dayjs(file.dateDoc) : '';
      const docType = DOCUMENT_TYPES.find((el) => el.value === file?.type)?.value;
      const kedoDocumentId = docInfo?.document.id;
      const signerId = docInfo.orgSigner.id;

      const preparedFields = {
        name,
        docNumber,
        docDate,
        docType,
        signerId: signerId,
        file: {
          fileName: file?.fileName,
          fileKey: file?.fileKey,
        },
        kedoDocumentId,
      };

      form.setFieldsValue(preparedFields);
    }
  }, [docInfo, form]);
};

export default useDefaultValues;
