import { useEffect, useMemo } from 'react';

import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules';
import { v4 as uuidv4 } from 'uuid';

import { Flex, Form, Icon, Tooltip, Typography } from 'gazprom-ui-lib';

import FileField from 'containers/file-field';

import { ADDITIONAL_DOCUMENTS_ENUM } from '../../utils';
import s from './File.module.scss';
import {
  AVAILABLE_TYPES,
  MAX_SIZE,
  getDownloadLink,
  getFileKey,
  getMetaLink,
  getUploadLink,
} from './file.utils';

interface Props {
  showDownload?: boolean;
  showDelete?: boolean;
}

const File = (props: Props) => {
  const { showDelete, showDownload } = props;

  const form = Form.useFormInstance();

  const { uploadLink, metaLink, kedoDocumentUuid } = useMemo(() => {
    const kedoDocumentUuid = uuidv4();

    return {
      kedoDocumentUuid,
      uploadLink: getUploadLink(kedoDocumentUuid),
      metaLink: getMetaLink(),
    };
  }, []);

  const fileData = Form.useWatch(ADDITIONAL_DOCUMENTS_ENUM.FILE);

  useEffect(() => {
    if (fileData?.url) {
      form.setFieldValue(ADDITIONAL_DOCUMENTS_ENUM.KEDO_DOCUMENT_UUID, kedoDocumentUuid);
    }
  }, [fileData, form, kedoDocumentUuid]);

  const fileLabel = (
    <Tooltip
      overlayClassName={s.tooltip}
      placement="bottomLeft"
      align={{ offset: [33, 16] }}
      title={t('common_pfd_a1_convert_file_description')}>
      <Flex align="center" gap="4">
        <Typography.Text weight="500" size="16" type="primary">
          {t('common_file')}
        </Typography.Text>

        <Icon name="info" color="var(--color-primary-6)" />
      </Flex>
    </Tooltip>
  );

  return (
    <FileField
      uploadLink={uploadLink}
      metaLink={metaLink}
      maxSize={MAX_SIZE}
      availableTypes={AVAILABLE_TYPES}
      getFileKey={getFileKey}
      getDownloadLink={getDownloadLink}
      formItemConfig={{
        label: fileLabel,
        name: ADDITIONAL_DOCUMENTS_ENUM.FILE,
        rules: [formRules.required],
      }}
      previewConfig={{
        downloadWithAuth: true,
        iconName: 'docTypePdf',
        showDelete,
        showDownload,
      }}
      uploadConfig={{
        description: t('validation_upload_file_format', { name: 'pdf' }),
      }}
      convert
    />
  );
};

export default File;
