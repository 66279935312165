import { Dispatch, SetStateAction, useCallback, useEffect } from 'react';

import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules';

import { Form, Input, Typography } from 'gazprom-ui-lib';

import DocumentsDeclarationsAdditionalLabel from 'pages/documents/documents-declarations/documents-declarations-additional/documents-declarations-additional-label';

import { DocumentsDeclarationsAdditionalSectionType } from 'types/documents.types';

import { DOCUMENTS_ADDITIONAL_DECLARATIONS_FORM_ENUM } from '../documentsDeclarationsForm.utils';
import s from './DocumentsDeclarationsFormFieldName.module.scss';

interface Props {
  id: string;
  setSections: Dispatch<SetStateAction<DocumentsDeclarationsAdditionalSectionType[]>>;
  removeHandler: () => void;
  defaultValue?: string;
  disabled?: boolean;
}

const DocumentsDeclarationsFormFieldName = (props: Props) => {
  const { id, defaultValue, setSections, removeHandler, disabled } = props;

  const fieldName = `${DOCUMENTS_ADDITIONAL_DECLARATIONS_FORM_ENUM.FIELD_NAME}.${id}`;
  const form = Form.useFormInstance();
  const textField = Form.useWatch(fieldName, form);

  const changeLabel = useCallback(
    (value: string) => {
      setSections((prev) => {
        return prev.map((section) => {
          return section.key.toString() === id.toString()
            ? {
                ...section,
                label: (
                  <DocumentsDeclarationsAdditionalLabel
                    attributeId={id}
                    title={value}
                    onClick={removeHandler}
                  />
                ),
              }
            : section;
        });
      });
    },
    [id, removeHandler, setSections],
  );

  useEffect(() => {
    changeLabel(textField);
  }, [changeLabel, textField]);

  return (
    <div>
      <div className={s.titleContainer}>
        <Typography.Title level={5}>{t('common_field_name')}</Typography.Title>
        <Typography.Text size="12" weight="400" type="secondary">
          {t('common_symbol_count', { current: textField?.length ?? 0, total: 80 })}
        </Typography.Text>
      </div>
      <Form.Item name={fieldName} initialValue={defaultValue} rules={[formRules.required]}>
        <Input placeholder={t('common_enter_text')} maxLength={80} disabled={disabled} />
      </Form.Item>
    </div>
  );
};

export default DocumentsDeclarationsFormFieldName;
