import dayjs from 'dayjs';
import { prepareQueryParams } from 'utils/prepareQueryParams';

import initialApiService from 'services/initialApiService';

import {
  CreateSalaryProjectsPropsType,
  CreateSalaryProjectsResponseType,
  DeleteSalaryProjectsPropsType,
  DeleteSalaryProjectsResponseType,
  GetSalaryProjectByIdPropsType,
  GetSalaryProjectByIdResponseType,
  GetSalaryProjectsPropsType,
  GetSalaryProjectsResponseType,
  UpdateSalaryProjectsPropsType,
  UpdateSalaryProjectsResponseType,
} from './privateApiService.types';
import { SERVICE_TAGS } from 'constants/serviceTags';
import { SalaryProjectType } from 'types/salaryProject.types';
import { SORT_ENUM } from 'types/sort.types';

import { createPrivateUrl } from './utils';

const privateApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getSalaryProjects: builder.query<GetSalaryProjectsResponseType, GetSalaryProjectsPropsType>({
      query: (params) => ({
        url: createPrivateUrl(`/salary-project${prepareQueryParams(params)}`),
      }),
      transformResponse: (response: GetSalaryProjectsResponseType, _, params) => {
        let res = response;

        if (params.search) {
          res = response.filter((item) =>
            item.name.toLowerCase().trim().includes(params.search!.trim().toLowerCase()),
          );
        }

        if (params.sort?.[0]) {
          const { order, property } = params.sort[0] as {
            order: SORT_ENUM;
            property: keyof SalaryProjectType;
          };

          res = res.sort((a: SalaryProjectType, b: SalaryProjectType) => {
            const aSortValue = String(a[property]);
            const bSortValue = String(b[property]);

            if (order === SORT_ENUM.ASC) {
              return dayjs(aSortValue).isAfter(dayjs(bSortValue)) ? 1 : -1;
            } else {
              return dayjs(aSortValue).isBefore(dayjs(bSortValue)) ? 1 : -1;
            }
          });
        }

        return res;
      },
      providesTags: [SERVICE_TAGS.GET_SALARY_PROJECTS],
    }),
    getSalaryProjectById: builder.query<
      GetSalaryProjectByIdResponseType,
      GetSalaryProjectByIdPropsType
    >({
      query: (id) => ({
        url: createPrivateUrl(`/salary-project/${id}`),
      }),
      providesTags: [SERVICE_TAGS.GET_SALARY_PROJECT_BY_ID],
    }),
    createSalaryProject: builder.mutation<
      CreateSalaryProjectsResponseType,
      CreateSalaryProjectsPropsType
    >({
      query: (body) => ({
        url: createPrivateUrl(`/salary-project`),
        method: 'POST',
        body,
      }),
      invalidatesTags: [SERVICE_TAGS.GET_SALARY_PROJECTS, SERVICE_TAGS.GET_SALARY_PROJECT_BY_ID],
    }),
    updateSalaryProject: builder.mutation<
      UpdateSalaryProjectsResponseType,
      UpdateSalaryProjectsPropsType
    >({
      query: ({ id, ...body }) => ({
        url: createPrivateUrl(`/salary-project/${id}`),
        method: 'PUT',
        body,
      }),
      invalidatesTags: [SERVICE_TAGS.GET_SALARY_PROJECTS, SERVICE_TAGS.GET_SALARY_PROJECT_BY_ID],
    }),
    deleteSalaryProject: builder.mutation<
      DeleteSalaryProjectsResponseType,
      DeleteSalaryProjectsPropsType
    >({
      query: (id) => ({
        url: createPrivateUrl(`/salary-project/${id}`),
        method: 'DELETE',
      }),
      invalidatesTags: [SERVICE_TAGS.GET_SALARY_PROJECTS, SERVICE_TAGS.GET_SALARY_PROJECT_BY_ID],
    }),
  }),
});

export const {
  useLazyGetSalaryProjectsQuery,
  useGetSalaryProjectByIdQuery,
  useCreateSalaryProjectMutation,
  useUpdateSalaryProjectMutation,
  useDeleteSalaryProjectMutation,
} = privateApiService;
