import { FC, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { getUsersByIdRoute } from 'routes/access-control/list';
import { useVisibility } from 'utils/useVisibility.hook';

import { GetPersonsResponseType } from 'services/person/personApiService.types';

import { Form } from 'gazprom-ui-lib';

import FiltersV2 from 'containers/filters-v2';

import { RolesPersonType } from 'types/person.types';

import { FormResponseType } from '../../accessControlUsers.utils';
import AccessCodeModal from '../access-code-modal';
import s from './Table.module.scss';
import useColumns from './useColumns.hook';

interface AccessControlUsersTableProps {
  dataSource?: GetPersonsResponseType;
  loading: boolean;
  isInitialLoading: boolean;
}

interface HandleOpenAccessCodeModalProps {
  personId: string;
}

const Table: FC<AccessControlUsersTableProps> = (props) => {
  const { dataSource, loading, isInitialLoading } = props;
  const navigate = useNavigate();
  const [selectedPersonId, setSelectedPersonId] = useState<string | null>(null);
  const [accessCodeModalIsOpened, { open, close }] = useVisibility();

  const handleOpenAccessCodeModal = ({ personId }: HandleOpenAccessCodeModalProps) => {
    setSelectedPersonId(personId);
    open();
  };

  const handleCloseAccessCodeModal = () => {
    close();
    setSelectedPersonId(null);
  };

  const columns = useColumns({ handleOpenAccessCodeModal: handleOpenAccessCodeModal });

  const requestBody = Form.useWatch([]);

  const onRow = (record: RolesPersonType) => ({
    onClick: () => navigate(getUsersByIdRoute(record.id), { state: { requestBody } }),
  });

  return (
    <>
      <FiltersV2.Table<RolesPersonType, FormResponseType>
        isInitialLoading={isInitialLoading}
        total={dataSource?.totalCount}
        dataSource={dataSource?.items}
        columns={columns}
        onRow={onRow}
        loading={loading}
        rowKey="id"
        className={s.table}
      />
      {selectedPersonId && (
        <AccessCodeModal
          personId={selectedPersonId}
          open={accessCodeModalIsOpened}
          close={handleCloseAccessCodeModal}
        />
      )}
    </>
  );
};

export default Table;
