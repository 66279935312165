import { MouseEventHandler, useState } from 'react';

import { useParams } from 'react-router-dom';
import { t } from 'tools/i18n';

import { useRemoveAttributeMutation } from 'services/documents/documents-declaration-types/documentsDeclarationTypesApiService';

import { Button, ModalDefault, Typography } from 'gazprom-ui-lib';

import s from './DocumentsDeclarationsAdditionalLabel.module.scss';

interface Props {
  attributeId?: string;
  title?: string;
  disabled?: boolean;
  onClick: () => void;
}

const DocumentsDeclarationsAdditionalLabel = (props: Props) => {
  const { title, attributeId, onClick, disabled } = props;
  const { id } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [removeSection] = useRemoveAttributeMutation();

  const mutationRemoveHandler = () => {
    if (attributeId) {
      removeSection({ id: id!, attributeId });
    }
  };

  const onModalCancel: MouseEventHandler<HTMLElement> = (e) => {
    e.stopPropagation();
    setIsModalOpen(!isModalOpen);
  };

  const onClickModalHandler: MouseEventHandler<HTMLElement> = (e) => {
    e.stopPropagation();

    onClick();
    mutationRemoveHandler();
  };

  return (
    <>
      <div className={s.container}>
        <Typography.Title level={5}>{title}</Typography.Title>
        <Button
          leftIcon="binFilled"
          disabled={disabled}
          htmlType="button"
          type="link"
          danger
          onClick={(e) => {
            e.stopPropagation();
            setIsModalOpen(!isModalOpen);
          }}>
          {t('common_delete')}
        </Button>
      </div>
      <ModalDefault open={isModalOpen} onCancel={onModalCancel} width={320} closable>
        <ModalDefault.Icon type="danger" name="bin" />
        <ModalDefault.Title>{t('common_accept_remove')}</ModalDefault.Title>
        <ModalDefault.Description>
          {t('documents_declarations_add_modal_description')}
        </ModalDefault.Description>
        <ModalDefault.Actions>
          <Button
            fullWidth
            type="secondary"
            onClick={onClickModalHandler}
            className={s.dangerButton}>
            {t('common_remove')}
          </Button>
        </ModalDefault.Actions>
      </ModalDefault>
    </>
  );
};

export default DocumentsDeclarationsAdditionalLabel;
