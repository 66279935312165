import { useCallback, useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { useDebounce } from 'utils/useDebounce.hook';

import { useEditKedoArchiveDocMutation } from 'services/kedo-archive-doc/kedoArchiveDocApiService';
import { EditKedoArchiveDocPropsType } from 'services/kedo-archive-doc/kedoArchiveDocApiService.types';

import { Input, InputProps } from 'gazprom-ui-lib';

import { KedoArchiveDocType } from 'types/kedoArchiveDoc.types';

interface EditableCellProps {
  row: KedoArchiveDocType;
  field: keyof EditKedoArchiveDocPropsType;
  maxLength?: number;
  placeholder?: string;
}

const EditableCell = (props: EditableCellProps) => {
  const { row, field, maxLength, placeholder } = props;

  const [localRow, setLocalRow] = useState({
    ...row,
    [field]:
      field === 'docDate' ? (row[field] ? dayjs(row[field]).format('DD.MM.YYYY') : '') : row[field],
  });

  const { debouncedValue } = useDebounce(localRow, 500);
  const [editArchiveDocument] = useEditKedoArchiveDocMutation();

  const handleSave = useCallback(
    async (updatedRow: KedoArchiveDocType) => {
      const { docDate, docNumber, docType } = row;

      const payload: EditKedoArchiveDocPropsType = {
        id: row.id,
        docName: row.docName,
        docNumber: field === 'docNumber' ? updatedRow.docNumber : docNumber,
        docDate:
          field === 'docDate' ? dayjs(updatedRow[field], 'DD.MM.YYYY').toISOString() : docDate,
        docType: field === 'docType' ? updatedRow.docType : docType,
      };
      await editArchiveDocument(payload);
    },
    [editArchiveDocument, field, row],
  );

  const shouldUpdateDate =
    field === 'docDate' &&
    debouncedValue[field] !== dayjs(row[field]).format('DD.MM.YYYY') &&
    debouncedValue.docDate.length === 10;
  const shouldUpdate = field !== 'docDate' && debouncedValue[field] !== row[field];

  useEffect(() => {
    if (shouldUpdate || shouldUpdateDate) {
      handleSave(debouncedValue);
    }
  }, [debouncedValue, field, handleSave, row, shouldUpdate, shouldUpdateDate]);

  const handleChange: InputProps['onChange'] = (event) => {
    let value = event.target.value;

    if (field === 'docDate') {
      value = value.replace(/[^\d]/g, '');

      if (value.length > 2) {
        value = `${value.slice(0, 2)}.${value.slice(2)}`;
      }
      if (value.length > 5) {
        value = `${value.slice(0, 5)}.${value.slice(5)}`;
      }
      if (value.length > 10) {
        value = value.slice(0, 10);
      }
    }

    setLocalRow((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  return (
    <Input
      placeholder={placeholder}
      maxLength={maxLength}
      value={localRow[field]}
      onChange={handleChange}
    />
  );
};

export default EditableCell;
