import { useEffect, useMemo, useState } from 'react';

import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useParams } from 'react-router-dom';
import { t } from 'tools/i18n';
import useHandleRequestWithModal from 'utils/useHandleRequestWithModal.hook';
import { useVisibility } from 'utils/useVisibility.hook';

import { useGetPersonByIdQuery } from 'services/person/personApiService';
import { useGetRolesQuery, useUpdateUserRoleMutation } from 'services/roles/rolesApiService';

import { Button, Form, Select, Typography } from 'gazprom-ui-lib';

import WithLoader from 'containers/wrappers/with-loader';

import AccessCodeModal from '../containers/access-code-modal';
import s from './AccessControlUsersDetails.module.scss';
import Header from './containers/header';
import Table from './containers/table';

const AccessControlUsersDetails = () => {
  const params = useParams();

  const [isTouched, setIsTouched] = useState<boolean>(false);

  const [form] = Form.useForm();

  const { data: person, isFetching } = useGetPersonByIdQuery(params.id ?? skipToken);
  const { data: roles } = useGetRolesQuery();
  const [updateUserRoles, { isLoading: isUpdateLoading }] = useUpdateUserRoleMutation();

  useEffect(() => {
    if (person?.roles)
      form.setFieldValue(
        'roles',
        person.roles.map((role) => role.code),
      );
  }, [person, form]);

  const handleUpdate = useHandleRequestWithModal({
    onSuccessModalConfig: {},
  });
  const onSubmit = (values: { roles: string[] }) => {
    setIsTouched(false);
    updateUserRoles({
      personId: params.id!,
      codes: values.roles,
    }).then(handleUpdate);
  };

  const options = useMemo(() => {
    return (roles ?? []).map((role) => ({
      value: role.code,
      label: role.name,
    }));
  }, [roles]);

  const [accessCodeModalIsOpened, { open, close }] = useVisibility();

  return (
    <>
      <WithLoader isLoading={isFetching}>
        <Header person={person} />
        <div className={s.content}>
          <Table dataSource={person?.employees} />
          {/* //TODO добавить отступ */}
          <div className={s.row}>
            <div className={s.column}>
              <Typography.Title level={5}>ID</Typography.Title>
              <Typography.Text size="14">{person?.id}</Typography.Text>
            </div>
          </div>
          <div className={s.row}>
            <div className={s.column}>
              <Typography.Title level={5}>{t('common_phone')}</Typography.Title>
              <Typography.Text size="14">{person?.phone}</Typography.Text>
            </div>
            <div className={s.column}>
              <Typography.Title level={5}>{t('common_email')}</Typography.Title>
              <Typography.Text size="14">{person?.email}</Typography.Text>
            </div>
          </div>
          <Form
            className={s.form}
            form={form}
            onFinish={onSubmit}
            onFieldsChange={() => setIsTouched(true)}>
            <Typography.Title level={5}>{t('common_roles')}</Typography.Title>
            <Form.Item name="roles">
              <Select optionFilterProp="label" mode="multiple" options={options} />
            </Form.Item>
            <div className={s.actionsFooter}>
              <Button
                loading={isUpdateLoading}
                leftIcon="ok"
                disabled={!isTouched}
                fullWidth
                type="primary"
                htmlType="submit">
                {t('common_save')}
              </Button>
              <Button
                disabled={!person?.refreshCode}
                leftIcon="sendFilled"
                loading={isFetching}
                type="secondary"
                fullWidth
                onClick={open}>
                {t('common_access_code_send')}
              </Button>
            </div>
          </Form>
        </div>
      </WithLoader>
      {person?.id && (
        <AccessCodeModal personId={person.id} open={accessCodeModalIsOpened} close={close} />
      )}
    </>
  );
};

export default AccessControlUsersDetails;
