import { useState } from 'react';

import { t } from 'tools/i18n';
import { FILE_UPLOAD_PREFIX } from 'utils/createDownloadUrl.utils';
import { formRules } from 'utils/formRules';

import { useCreateKedoReviewTaskMutation } from 'services/kedo-review-tasks/kedoReviewTasksApiService';
import { CreateKedoReviewTaskPropsType } from 'services/kedo-review-tasks/kedoReviewTasksApiService.types';

import {
  Button,
  DatePicker,
  Flex,
  Form,
  Input,
  Modal,
  TextArea,
  Typography,
  handleRequest,
} from 'gazprom-ui-lib';

import { FORM_IMAGE_ENUM } from 'containers/image-modal/imageModal.utils';

import s from './CreateModal.module.scss';
import FileItem from './file-item';

interface Props {
  visible: boolean;
  instanceId: string;
  handleClose: () => void;
  openAssignModal: (id: string) => void;
}

const CreateModal = (props: Props) => {
  const { visible, handleClose, instanceId, openAssignModal } = props;
  const [form] = Form.useForm();

  const docName = Form.useWatch('docName', form);
  const docNumber = Form.useWatch('docNumber', form);
  const docDate = Form.useWatch('docDate', form);

  const [kedoReviewTaskId, setKedoReviewTaskId] = useState<string | null>(null);
  const [createKedoReviewTask, { isLoading }] = useCreateKedoReviewTaskMutation();

  const file: { name: string; url: string } = Form.useWatch(FORM_IMAGE_ENUM.FULL_INFO, form);

  const createData = (id: string) => {
    let data: CreateKedoReviewTaskPropsType = {
      id: id,
      name: t('1c_lna_introduction'),
      docName,
      needEmployeeSign: true,
      files: [],
      type: 'LNA',
      status: 'LNA_CREATED',
      instanceId,
      docNumber,
      docDate,
    };

    if (file && data.files) {
      data.files.push({
        fileName: file.name,
        fileKey: file.url.includes(FILE_UPLOAD_PREFIX)
          ? file.url.replace(FILE_UPLOAD_PREFIX, '')
          : file.url,
      });
    }

    return data;
  };

  const handleSave = () => {
    if (kedoReviewTaskId) {
      form.validateFields().then(() => {
        const data = createData(kedoReviewTaskId);

        createKedoReviewTask(data).then(
          handleRequest({
            onSuccess: () => {
              form.resetFields();
              setKedoReviewTaskId(null);
            },
          }),
        );
      });
    }
  };

  const handleAppoint = () => {
    if (kedoReviewTaskId) {
      form.validateFields().then(() => {
        const data = createData(kedoReviewTaskId);

        createKedoReviewTask(data).then(
          handleRequest({
            onSuccess: (response) => {
              const id = response?.id;
              form.resetFields();
              handleClose();
              setKedoReviewTaskId(null);
              id && openAssignModal(id);
            },
          }),
        );
      });
    }
  };

  const handleCreateKedoReviewTaskId = () => {
    setKedoReviewTaskId(crypto.randomUUID());
  };

  return (
    <Modal open={visible} onCancel={handleClose} closable width={420}>
      <Typography.Title level={4}>{t('1c_lna_create')}</Typography.Title>

      <Form form={form}>
        <div className={s.body}>
          <Flex align="center" justify="space-between">
            <Typography.Text size="14" weight="500">
              {t('common_document_name')}
            </Typography.Text>
            <Typography.Text size="12" type="secondary">
              {t('common_symbol_count', {
                current: docName?.length ?? 0,
                total: 240,
              })}
            </Typography.Text>
          </Flex>
          <Form.Item
            name="docName"
            rules={[formRules.required]}
            normalize={(e: string) => e.trimStart()}>
            <TextArea placeholder={t('common_enter_text')} maxLength={240} />
          </Form.Item>
          <Flex gap="16" className={s.docks}>
            <Form.Item
              name="docNumber"
              rules={[formRules.required]}
              label={
                <Typography.Text size="14" weight="500">
                  {t('common_document_number')}
                </Typography.Text>
              }>
              <Input placeholder={t('common_enter_text')} type="number" className={s.noSpinners} />
            </Form.Item>
            <Form.Item
              name="docDate"
              rules={[formRules.required]}
              label={
                <Typography.Text size="14" weight="500">
                  {t('common_document_date')}
                </Typography.Text>
              }>
              <DatePicker format="DD.MM.YYYY" placeholder={t('common_select_date')} />
            </Form.Item>
          </Flex>

          <FileItem
            handleCreateTaskId={handleCreateKedoReviewTaskId}
            kedoReviewTaskId={kedoReviewTaskId}
            uploadTitle={t('common_upload_a_file')}
            availableTypes={['application/pdf']}
            uploadDescription={t('validation_upload_a_pdf_restrictions')}
            maxLimitSizeMB={10}
          />
        </div>
        <Flex gap="8">
          <Button
            type="secondary"
            leftIcon="ok"
            htmlType="submit"
            onClick={handleSave}
            fullWidth
            loading={isLoading}>
            {t('common_save')}
          </Button>
          <Button
            type="primary"
            leftIcon="publish"
            onClick={handleAppoint}
            fullWidth
            loading={isLoading}>
            {t('common_appoint')}
          </Button>
        </Flex>
      </Form>
    </Modal>
  );
};

export default CreateModal;
