import { t } from 'tools/i18n';
import { getFio } from 'utils/getFio.utils';

import { Avatar, Button, Flex, Modal, Typography } from 'gazprom-ui-lib';

import { CommunityTypeWithModerators } from 'types/community.types';

type ModeratorsModalProps = {
  open: boolean;
  close: () => void;
  moderators: CommunityTypeWithModerators['moderators'];
};

const ModeratorsModal = ({ open, close, moderators }: ModeratorsModalProps) => {
  const renderedModerators = moderators.map(
    ({ id, person: { firstName, lastName, patronymic } }) => {
      const fio = getFio({ firstName, lastName, patronymic, type: 'full' });
      return (
        <Flex key={id} gap="8">
          <Avatar firstName={firstName} lastName={lastName} alt="аватар пользователя" size="40" />
          <Flex vertical justify="center">
            <Typography.Text size="14" weight="500">
              {fio}
            </Typography.Text>
          </Flex>
        </Flex>
      );
    },
  );

  return (
    <Modal width={360} open={open} onCancel={close} closable>
      <Flex vertical gap="16">
        <Typography.Text size="18" weight="500">
          {t('community_moderators')}
        </Typography.Text>
        <Flex vertical gap="12">
          {renderedModerators}
        </Flex>
        <Button type="secondary" onClick={close}>
          {t('common_close')}
        </Button>
      </Flex>
    </Modal>
  );
};

export default ModeratorsModal;
