import { t } from 'tools/i18n';

import { GetTaskByIdResponseType } from 'services/1c/cApiService.types';

import { Button, Flex } from 'gazprom-ui-lib';

import CancelOrgApproveButton from 'pages/documents/documents-signs/documents-signs-details/containers/cancel-org-approve-modal';

import { DOCUMENT_VIEW_PERSON_ENUM, PersonComponentParamsType } from 'types/documents.types';
import { TASK_ASSIGNMENT_TYPE_ENUM } from 'types/taskAssignment.types';

export const findKey =
  (personId: string) => (sign: GetTaskByIdResponseType['files']['0']['signs'][0]) =>
    sign.key.includes(personId);

export const getComponentsParams = ({
  onClick,
  onReject,
  rejected,
  approved,
  waiting,
  signed,
  type,
  onClickOrgRequest,
  onRejectOrgRequest,
}: {
  onClick: () => void;
  onReject?: () => void;
  rejected?: boolean;
  approved: boolean;
  waiting: boolean;
  signed?: boolean;
  type: TASK_ASSIGNMENT_TYPE_ENUM;
  onClickOrgRequest?: () => void;
  onRejectOrgRequest?: () => void;
}): PersonComponentParamsType | undefined => {
  if (approved) {
    return {
      button: (
        <Button leftIcon="ok" disabled>
          {t(
            type === TASK_ASSIGNMENT_TYPE_ENUM.CANCEL_ORG_REQUEST
              ? 'common_agreed'
              : 'common_signed',
          )}
        </Button>
      ),
      isMe: true,
      icon: 'ok',
      type: DOCUMENT_VIEW_PERSON_ENUM.SUCCESS,
    };
  }

  if (rejected) {
    return {
      button: (
        <Button leftIcon="delete" disabled>
          {t('common_rejected')}
        </Button>
      ),
      isMe: true,
      icon: 'delete',
      type: DOCUMENT_VIEW_PERSON_ENUM.ERROR,
    };
  }

  if (signed && !waiting) {
    return {
      button: (
        <Button leftIcon="ok" disabled>
          {t('common_signed')}
        </Button>
      ),
      isMe: true,
      icon: 'ok',
      type: DOCUMENT_VIEW_PERSON_ENUM.SUCCESS,
    };
  }

  if (onClickOrgRequest && onRejectOrgRequest) {
    return {
      button: (
        <Flex gap="8">
          <CancelOrgApproveButton />

          <Button onClick={onRejectOrgRequest} danger type="secondary">
            {t('common_reject')}
          </Button>
        </Flex>
      ),
      isMe: true,
      icon: 'time',
      type: DOCUMENT_VIEW_PERSON_ENUM.WARNING,
    };
  }

  return {
    button: (
      <Flex gap="8">
        <Button onClick={onClick} leftIcon="signature">
          {t('common_sign')}
        </Button>
        {onReject && (
          <Button onClick={onReject} danger type="secondary">
            {t('common_reject')}
          </Button>
        )}
      </Flex>
    ),
    isMe: true,
    icon: 'time',
    type: DOCUMENT_VIEW_PERSON_ENUM.WARNING,
  };
};

export const getArchiveUrl = (id: string, fileKey: string) =>
  `${process.env.https://admin-gateway.dev.tockl.ru/api/v1/}kedo-file/archive?kedoTaskId=${id}&full=true&fileKey=${fileKey}`;

export const getFileUrl = (id: string, fileKey: string) =>
  `${process.env.https://admin-gateway.dev.tockl.ru/api/v1/}kedo-file/task/${id}/download?fileKey=${fileKey}`;
export const archiveName = 'archive.zip';
