import { t } from 'tools/i18n';
import useHandleRequestWithModal from 'utils/useHandleRequestWithModal.hook';

import { useRefreshRegistrationCodeMutation } from 'services/person/personApiService';

import { Button, Flex, ModalDefault } from 'gazprom-ui-lib';

import { REFRESH_CODE_TYPE } from 'types/person.types';

interface Props {
  open: boolean;
  close: () => void;
  personId: string;
}

const AccessCodeModal = ({ open, close, personId }: Props) => {
  const [refreshRegistrationCode, { isLoading }] = useRefreshRegistrationCodeMutation();

  const handleRefreshRegistrationCode = useHandleRequestWithModal({
    onSuccessModalConfig: {
      title: {
        children: t('common_successfully_sent'),
      },
    },
  });

  const handleSubmit = (refreshCodeType: REFRESH_CODE_TYPE) => {
    refreshRegistrationCode({ personId, payload: { refreshCodeType } }).then((data) => {
      close();
      handleRefreshRegistrationCode(data);
    });
  };

  return (
    <ModalDefault open={open} onCancel={close} closable>
      <ModalDefault.Icon type="primary" name="sendFilled" />
      <ModalDefault.Title>{t('common_registration_code_send')}</ModalDefault.Title>
      <ModalDefault.Description>{t('common_access_code_description')}</ModalDefault.Description>
      <ModalDefault.Actions>
        <Flex gap="8">
          <Button
            disabled={isLoading}
            onClick={() => handleSubmit(REFRESH_CODE_TYPE.SMS)}
            fullWidth
            leftIcon="sms"
            type="primary">
            {t('common_via_sms')}
          </Button>
          <Button
            disabled={isLoading}
            onClick={() => handleSubmit(REFRESH_CODE_TYPE.EMAIL)}
            fullWidth
            leftIcon="mail"
            type="secondary">
            {t('common_via_mail')}
          </Button>
        </Flex>
      </ModalDefault.Actions>
    </ModalDefault>
  );
};

export default AccessCodeModal;
