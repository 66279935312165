import { useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { COMMUNITIES_ROUTE } from 'routes/communities/list';
import { getFio } from 'utils/getFio.utils';

import {
  useCreateCommunityMutation,
  useGetCommunityQuery,
  useUpdateCommunityMutation,
} from 'services/communities/communitiesApiService';

import { Form } from 'gazprom-ui-lib';

import CommunityManagementForm from 'containers/community-management-form';
import {
  COMMUNITY_MANAGEMENT_FORM_ENUM,
  CommunityManagementFormResponse,
} from 'containers/community-management-form/communityManagementForm.utils';

import s from './CommunityManagementCreateEdit.module.scss';
import { preparePayload } from './communityManagementCreateEdit.utils';
import Header from './containers/header';

const CommunityManagementCreateEdit = () => {
  const { id: communityId } = useParams<{ id: string }>() as { id: string };
  const isUpdate = !!communityId;
  const navigate = useNavigate();

  const { data: community } = useGetCommunityQuery({ id: communityId }, { skip: !isUpdate });

  const [form] = Form.useForm<CommunityManagementFormResponse>();

  useEffect(() => {
    if (community) {
      form.setFields([
        { name: COMMUNITY_MANAGEMENT_FORM_ENUM.NAME, value: community.name },
        { name: COMMUNITY_MANAGEMENT_FORM_ENUM.DESCRIPTION, value: community.description },
        { name: COMMUNITY_MANAGEMENT_FORM_ENUM.AVATAR, value: community.avatar?.link },
        { name: COMMUNITY_MANAGEMENT_FORM_ENUM.COVER, value: community.cover?.link },
        { name: COMMUNITY_MANAGEMENT_FORM_ENUM.TYPE, value: community.type },
        {
          name: COMMUNITY_MANAGEMENT_FORM_ENUM.CATEGORIES,
          value: community.categories.map(({ id }) => id),
        },
        { name: COMMUNITY_MANAGEMENT_FORM_ENUM.LINKS, value: community.links },
        {
          name: COMMUNITY_MANAGEMENT_FORM_ENUM.MODERATORS,
          value: community.moderators.map(
            ({
              id: employeeId,
              person: { firstName, lastName, patronymic, id: personId },
              position,
            }) => ({
              value: JSON.stringify({ employeeId, personId }),
              label: `${getFio({ firstName, lastName, patronymic, type: 'full' })} / ${
                position.name
              }`,
            }),
          ),
        },
        { name: COMMUNITY_MANAGEMENT_FORM_ENUM.IS_PUBLISH, value: community.isPublish },
      ]);
    }
  }, [community, form]);

  const [createCommunity, { isLoading: isCreatingCommunity }] = useCreateCommunityMutation();
  const [updateCommunity, { isLoading: isUpdatingCommunity }] = useUpdateCommunityMutation();

  const handleSubmit = async (payload: CommunityManagementFormResponse) => {
    const preparedPayload = preparePayload(payload);
    try {
      if (isUpdate) {
        const { id } = await updateCommunity({
          payload: preparedPayload,
          id: communityId,
        }).unwrap();
        navigate(`${COMMUNITIES_ROUTE}/management/view/${id}`);
      } else {
        const { id } = await createCommunity({ payload: preparedPayload }).unwrap();
        navigate(`${COMMUNITIES_ROUTE}/management/view/${id}`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const isCreatingOrLoading = isCreatingCommunity || isUpdatingCommunity;

  return (
    <>
      <Header
        isCreatingOrLoading={isCreatingOrLoading}
        submitForm={form.submit}
        isUpdate={isUpdate}
      />
      <CommunityManagementForm
        isCreatingOrUpdating={isCreatingOrLoading}
        className={s.formWrapper}
        handleSubmit={handleSubmit}
        form={form}
      />
    </>
  );
};

export default CommunityManagementCreateEdit;
