import { useNavigate } from 'react-router-dom';
import { EMPLOYEE_GROUPS_ROUTE } from 'routes/employee-groups/list';
import { t } from 'tools/i18n';

import { CONDITION_SURVEY_ENUM } from 'services/surveys/surveysApiService.types';

import { Button, Flex, Typography } from 'gazprom-ui-lib';

import CommonCheck from 'components/form/common-check';
import CommonSelect from 'components/form/common-select';
import MultiTagsSelect from 'components/form/multi-tags-select';

import useMatchOnceRoute from '../useMatchOnceRoute';
import useSurveyFormData from '../useSurveyFormData.hook';
import s from './FormBody.module.scss';
import AdditionalInfo from './containers/additional-info';
import { CONDITION_OPTIONS } from './formBody.utils';

const FormBody = () => {
  const navigate = useNavigate();
  const isOnceSurveysRoute = useMatchOnceRoute();

  const { employeeGroupsResult, organizationOptions, selectData, surveysList, surveySettings } =
    useSurveyFormData();

  const initialCondition = isOnceSurveysRoute
    ? CONDITION_SURVEY_ENUM.ONCE
    : surveySettings?.condition;

  return (
    <>
      <div className={s.nameContainer}>
        <CommonSelect
          name="name"
          title={t('surveys_select_survey')}
          placeholder={t('surveys_select_survey_placeholder')}
          options={surveysList?.options!}
          className={s.selectWithoutMargin}
          initialValue={surveySettings?.info.anketologId}
        />
        {selectData.name && (
          <AdditionalInfo
            id={
              surveySettings?.info.anketologId ??
              surveysList!.surveysInfo[selectData.name].anketologId
            }
            revisionId={
              surveySettings?.info.revisionId ??
              surveysList!.surveysInfo[selectData.name].revisionId
            }
          />
        )}
      </div>

      <CommonCheck
        name="isMandatory"
        checkboxText={t('surveys_add_mandatory')}
        initialValue={surveySettings?.isMandatory ?? false}
      />
      <CommonCheck
        name="isMain"
        checkboxText={t('surveys_show_on_main')}
        initialValue={surveySettings?.isMain ?? false}
      />

      <CommonSelect
        name="condition"
        title={t('surveys_type')}
        placeholder={t('surveys_select_type')}
        initialValue={initialCondition}
        options={CONDITION_OPTIONS}
        hidden={!!isOnceSurveysRoute}
      />

      <Flex vertical gap="32" className={s.additional}>
        <Typography.Title level={4}>{t('surveys_survey_for')}</Typography.Title>
        <div>
          <MultiTagsSelect
            name="organization"
            title={t('common_select_organization')}
            placeholder={t('common_select_placeholder')}
            options={organizationOptions ?? []}
            initialValues={surveySettings?.receivers.EMPLOYER}
            isRequired={false}
          />
          <MultiTagsSelect
            name="receivers"
            title={t('surveys_add_group')}
            placeholder={t('surveys_select_group')}
            options={employeeGroupsResult ?? []}
            initialValues={surveySettings?.receivers.EMPLOYEE_GROUP}
            className={s.receiversContainer}
            isRequired={false}
          />
          <Button
            type="link"
            leftIcon="groups"
            className={s.receiversLink}
            onClick={() => navigate(EMPLOYEE_GROUPS_ROUTE)}
            disabled={false}>
            {t('surveys_groups_management')}
          </Button>
        </div>
      </Flex>
    </>
  );
};

export default FormBody;
